<template>
  <Wrapper>
    <div class="mb-3 text-left">
      <h2 class="text-xl"><b>Información del usuario</b></h2>
      <p class="text-gray-500">
        Edite la información del usuario y el tipo de rol administrativo
      </p>
    </div>
    <form @submit.prevent="createUser" class="mx-auto max-w-2xl" ref="form">
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseInput
          ref="names"
          type="text"
          label="Nombres"
          placeholder="Ej: Carlos Andrés"
          v-model="form.first_name"
          :maxlength="25"
          required="true"
        />
        <BaseInput
          ref="lastnames"
          type="text"
          label="Apellidos"
          placeholder="Ej: Jaramillo Pastrana"
          v-model="form.last_name"
          :maxlength="24"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseInput
          ref="documentType"
          type="text"
          label="Tipo de documento"
          v-model="form.identification_type"
          placeholder="Ej: Cédula de ciudadanía"
          :disabled="true"
          required="true"
        />
        <BaseInput
          ref="documentNumber"
          type="text"
          label="No. de documento"
          v-model="form.identification_number"
          placeholder="Ej: 10254785633"
          :disabled="true"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseInput
          ref="phoneNumber"
          type="text"
          phoneNumber
          maxlength="15"
          label="Número de teléfono"
          v-model="form.phone"
          placeholder="Ej: +573103102001"
          required="true"
        />
        <BaseInput
          ref="email"
          type="text"
          label="Correo electrónico"
          v-model="form.email"
          placeholder="Ej: consumidor@consumidor.com"
          :maxlength="50"
          required="true"
        />
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <BaseSelect
          ref="cargo"
          label="Cargo"
          :selectOptions="formOptions.optionsPosition"
          v-model="form.position"
          required="true"
        />
        <BaseSelect
          ref="rol"
          label="Tipo de rol"
          :selectOptions="optionsGroupCompany"
          v-model="form.groups"
          required="true"
        />
      </div>
      <BaseSelect
        ref="direction"
        label="Dirección"
        :selectOptions="formOptions.optionsDirections"
        v-model="form.direction"
        required="true"
      />
      <div v-if="feedback">
        <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>
      <div
        class="w-full lg:max-w-sm mt-8 ml-auto flex flex-col lg:flex-row lg:justify-end gap-5"
      >
        <a-button
          type="danger"
          @click="$router.go(-1)"
          shape="round"
          :block="true"
          :loading="loading"
          ghost
        >
          <span class="mx-2">CANCELAR</span>
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          shape="round"
          :block="true"
          :loading="loading"
          :disabled="!changedForm"
        >
          <span class="mx-2">GUARDAR</span>
        </a-button>
      </div>
    </form>
  </Wrapper>
</template>

<script>
import form from '../../../store/modules/form.store';

export default {
  data() {
    return {
      loading: false,
      feedback: null,
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        identification_type: "",
        identification_number: "",
        position: "",
        groups: "",
        person_type: 1,
        is_active: true,
        direction: "",
      },
      oldForm :{},
    };
  },

  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.getDataUser(id);
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    async getDataUser(id) {
      this.loading = true;
      let { error, data } = await this.$api.getAdminUser(id);
      this.loading = false;

      if (error)
        this.showToast("error", "Error consultando los datos del usuario.");

      if (data) {
        this.form = {
          ...data,
          groups:
            this.optionsGroupCompany.filter(
              (option) => option.id == data.groups[0]
            ).length > 0
              ? data.groups[0] + ""
              : "",
          identification_number: data.person?.identification,
          identification_type: data.person?.type_identification_name,
          position: data.person?.position + "",
          direction: data.direction ? data.direction + "" : "",
        };
        this.oldForm = JSON.stringify(this.form)
      }
    },
    async createUser() {
      if (!this.checkForm()) {
        this.showToast("error", "Todos los campos son obligatorios.");
        return;
      }
      const myForm = {
        id: this.form.id,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        phone: this.form.phone,
        email: this.form.email.toLowerCase(),
        groups: [+this.form.groups],
        position: +this.form.position,
        direction: +this.form.direction,
      };
      this.loading = true;
      let { error, data } = await this.$api.updateUser(myForm, this.form.id);
      this.loading = false;

      if (error)
        this.showToast("error", "Error actualizando los datos del usuario.");

      if (data) {
        if (data.code === 200) {
          this.showToast("success", "Usuario actualizado correctamente.");
          try {
            if (this.$store.state.session.user.email === myForm.email) {
              this.$store.dispatch("session/loadUser");
              this.$router.push("/dashboard/home");
            } else {
              this.$router.go(-1);
            }
          } catch (err) {
            this.$router.go(-1);
          }
        } else if (data.code === 401) {
          this.showToast("error", data.error);
        } else if (data.code === 402) {
          this.showToast("error", data.error);
        }
      }
    },
    checkForm() {
      this.feedback = null;
      let valid = false;
      let refs = { ...this.$refs };
      for (let ref in refs) {
        if (refs[ref] && refs[ref].required && refs[ref].value === "") {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      if (this.$validator.isEmpty(this.form.first_name)) {
        this.feedback = "Introduzca el nombre completo del usuario";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.last_name)) {
        this.feedback = "Introduzca los apellidos del usuario";
        return valid;
      }

      this.form.phone = this.form.phone.replace(/\s/g, "");
      if (this.form.phone && this.form.phone.toString().length < 7) {
        this.feedback = "Ingrese un número de teléfono válido.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length > 15) {
        this.feedback = "El número de teléfono no puede ser mayor a 15.";
        return valid;
      }
      if (!this.$validator.isEmail(this.form.email)) {
        this.feedback = "Introduzca un correo válido";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.position + "")) {
        this.feedback = "Seleccione un cargo";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.groups + "")) {
        this.feedback = "Seleccione un rol";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.direction + "")) {
        this.feedback = "Seleccione una dirección";
        return valid;
      }
      return true;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    optionsGroupCompany: function () {
      return this.$store.state.form.optionsGroupCompany.filter(
        (option) => option?.detail_group?.state !== false
      );
    },
    changedForm: function () {
     if(this.oldForm !== JSON.stringify(this.form)){
      return true
     }
     return false
    },
  },
 
};
</script>

<style></style>
