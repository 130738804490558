<template>
  <div>
      <EditUser />
  </div>
</template>

<script>
import EditUser from '@/components/Dashboard/Users/EditUser'
export default {
    components: {
        EditUser
    }
}
</script>

<style>

</style>